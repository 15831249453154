body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Some of the global styles for buttons */

.btn-cs {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold !important;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}

.btn-cs:hover,
.btn-cs:focus,
.btn:active {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.btn-medium {
  width: 110px;
  height: 30px;
  line-height: 30px !important;
}
.color-primary {
  background-color: #0275d8;
}

.color-danger {
  background-color: #d5413e;
}

.color-cancel {
  background-color: #292b2c;
}

/* CSS */
.btn-download {
  appearance: none;
  opacity: 0.9;
  background-image: linear-gradient(#464d55, #25292e);
  border: 2px solid #1a1a1a;
  border-radius: 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.125rem !important;
  font-weight: bold !important;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: underline;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.btn-download:disabled {
  pointer-events: none;
}

.btn-download:hover {
  box-shadow: rgb(0 1 0 / 20%) 0 2px 8px;
  opacity: 1;
  transform: translateY(-2px);
}

.btn-download:active {
  box-shadow: none;
  transform: translateY(0);
}
